<template>
  <div class="pbf grid_width growers_boxs">

      <SkGrowerRows 
        v-if="is_loading && start == 0"
        />

      <template v-else-if="grower_data?.items" v-for="pro in grower_data.items">  
        <GeneralGrower 
          :data="pro"      
          />       

      </template> 


      <client-only>        
        <InfinityScroll 
          v-if="grower_data?.items?.length >= limit"
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>

  </div>
</template>

<script setup>



import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const is_ended = ref(false)
const {$api, $adv, $tagsUtil, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: true
  },
  intags: {
    type: Object,
    required: true
  },
  brandId: {
    type: Number,
    required: false
  },
  productId: {
    type: Number,
    required: false
  },
})

const start = ref(0);
const limit = ref(20);
const query = ref('');
const sort = ref('likes');
const tagscount = ref([]);
const tagsselected = ref([]);
const tagspermanent = ref([]);
const tagsdefault = ref(null);
const list = ref([]);

if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.productId){
  tagspermanent.value.push('pr:' + props.productId);
}

 
const clearPagination = function(){     
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

 

const loadData = async function() {  
  const response = await $api.getGrowers({
    start: start.value,
    limit: limit.value,
    q: $tagsUtil.getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: $tagsUtil.getTagsKeys(props.intags),
    tags: $tagsUtil.getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: grower_data } = await useLazyAsyncData('grower_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    grower_data.value.items = [];
    is_ended.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    is_ended.value = true;  

  grower_data.value.items = [...grower_data.value.items, ...dt.items]
  // grower_data.value.items_tags = [...grower_data.value.items_tags, ...dt.items_tags]
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}

</style>
